import React from 'react';
import { Link } from 'gatsby';
import '../style/style.scss';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const Post = props => {
  const post = props.data.mdx;
  const { previous, next } = props.pageContext;
  return (
    <>
      <div className="row">
        <div className="col">
          <article>
            <header className="text-center mb-5">
              <h2 className="mb-1">{post.frontmatter.title}</h2>
              <sub>
                <span role="img" aria-label="date">
                  📅
                </span>
                {` ${post.frontmatter.date} `}
              </sub>
              {post.frontmatter.linkonly ? (
                <>
                  {' '}
                  <br />
                  <sub>&mdash; Link only access &mdash;</sub>{' '}
                </>
              ) : null}
            </header>
            <section>
              <MDXRenderer>{post.body}</MDXRenderer>
            </section>
            <hr />
          </article>

          <nav>
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {!post.frontmatter.linkonly && previous ? (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                ) : null}
              </li>
              <li>
                {!post.frontmatter.linkonly && next ? (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                ) : null}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Post;
