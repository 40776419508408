import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Post from '../view/post';
import Obsession from '../view/obsession';
import '../style/style.scss';
const template = props => {
  return (
    <Layout {...props}>
      {props.data.mdx.fields.slug.indexOf('/post') === 0 ? (
        <Post {...props} />
      ) : (
        <Obsession {...props} />
      )}
    </Layout>
  );
};

export default template;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        linkonly
      }
      fields {
        slug
        readingTime {
          text
        }
      }
    }
  }
`;
